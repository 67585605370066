import { Item, Area } from '../class/export';
import { history } from '../utils/export';
import {
  TOGGLE_LOADING_CABINET,
  SELECT_TOOL_DRAWING_ITEM,
  UPDATE_DRAWING_ITEM,
  END_DRAWING_ITEM,
  BEGIN_DRAGGING_ITEM,
  BEGIN_DRAGGING_ITEM_3D,
  UPDATE_DRAGGING_ITEM,
  UPDATE_DRAGGING_ITEM_CHANGED,
  UPDATE_DRAGGING_ITEM_3DX,
  UPDATE_DRAGGING_ITEM_3DY,
  END_DRAGGING_ITEM,
  END_DRAGGING_ITEM_3D,
  BEGIN_ROTATING_ITEM,
  BEGIN_ROTATING_ITEM_3D,
  UPDATE_ROTATING,
  UPDATE_ROTATING_ITEM,
  UPDATE_ROTATING_ITEM_CHANGED,
  END_ROTATING_ITEM,
  END_ROTATING_ITEM_3D,
  REPLACE_SUBMODULE,
  SELECT_ITEM,
  ANIMATE_OBJECT,
  REMOVE_REPLACE_SUBMODULE,
  ITEM_MOVE_UP,
  SELECT_TOOL_DRAWING_ITEM_3D,
  SET_DOOR_STYLE,
  SET_HANDLE_MATERIAL,
  SET_INITIAL_DOOR_STYLE,
  UPDATE_ITEM_POSITION,
  SET_DOOR_HANDLE,
  SET_WALL_COLOR,
  END_CREATING_CABINET,
  UPDATE_POPUP_OPEN,
  SET_MODELLING,
  SET_COUNTER_TOP,
  SET_BACKSPLASH,
  SET_BACKSPLASH_VISIBLE,
  SET_APPLIANCE_MATERIAL,
  DUPLICATE_SELECTED,
  EDIT_WIDTH,
  END_LOADING,
  SET_MOLDING,
  SET_LINEAR,
  STORE_DIST_ARRAY,
  VALIDATE_ITEM_POSTIONS,
  REPLACE_ITEM,
  // SET_MOVE_STATUS,
  // SET_ROTATE_STATUS
} from '../constants';

export default function (state, action) {
  switch (action.type) {
    case STORE_DIST_ARRAY : 
      return Item.storeDistArray(state, action.layerID, action.itemID, action.distArray).updatedState;
    case VALIDATE_ITEM_POSTIONS :
      return Item.validateItemPositions(state, action.layerID).updatedState;
    case END_LOADING:
      return Item.endLoading(state).updatedState;
    case TOGGLE_LOADING_CABINET:
      return Item.toggleLoadingCabinet(state).updatedState;
    case EDIT_WIDTH:
      return Item.editWidth(state, action.newWidth, action.layerID, action.itemID).updatedState;
    case DUPLICATE_SELECTED:
      return Item.duplicateSelected(state, action.currentObject).updatedState;
    case END_CREATING_CABINET:
      return Item.endCreatingCabinet(state).updatedState;
    case UPDATE_POPUP_OPEN:
      return Item.updatePopupOpen(state, action.value).updatedState;
    case SELECT_ITEM:
      return Item.select(state, action.layerID, action.itemID).updatedState;
    case SELECT_TOOL_DRAWING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.selectToolDrawingItem(state, action.sceneComponentType, action.oStyle).updatedState;
    case REPLACE_ITEM:
      return Item.replaceItem(state, action.selectedPos, action.currentObject, action.selectedObject).updatedState;
    case UPDATE_DRAWING_ITEM:
      return Item.updateDrawingItem(state, action.layerID, action.x, action.y).updatedState;

    case END_DRAWING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.endDrawingItem(state, action.layerID, action.x, action.y).updatedState;

    case BEGIN_DRAGGING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.beginDraggingItem(state, action.layerID, action.itemID, action.x, action.y).updatedState;

    case BEGIN_DRAGGING_ITEM_3D:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.beginDraggingItem3D(state, action.layerID, action.itemID, action.x, action.y).updatedState;

    case UPDATE_DRAGGING_ITEM:
      return Item.updateDraggingItem(state, action.x, action.y).updatedState;

    case UPDATE_DRAGGING_ITEM_CHANGED:
      return Item.updateDraggingItemChanged(state, action.x, action.y, action.layerID, action.itemID).updatedState;

    case UPDATE_DRAGGING_ITEM_3DX:
      return Item.updateDraggingItem3DX(state, action.x).updatedState;

    case UPDATE_DRAGGING_ITEM_3DY:
      return Item.updateDraggingItem3DY(state, action.y).updatedState;

    case END_DRAGGING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.endDraggingItem(state, action.x, action.y).updatedState;

    case END_DRAGGING_ITEM_3D:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.endDraggingItem3D(state).updatedState;

    case BEGIN_ROTATING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.beginRotatingItem(state, action.layerID, action.itemID, action.x, action.y).updatedState;

    case BEGIN_ROTATING_ITEM_3D:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.beginRotatingItem3D(state, action.layerID, action.itemID, action.x, action.y, action.centerX, action.centerY).updatedState;

    case UPDATE_ROTATING_ITEM:
      return Item.updateRotatingItem(state, action.x, action.y).updatedState;

    case UPDATE_ROTATING:
      return Item.updateRotating(state, action.rotation).updatedState;

    case UPDATE_ROTATING_ITEM_CHANGED:
      return Item.updateRotatingItemChanged(state, action.rotation, action.layerID, action.itemID).updatedState;

    case END_ROTATING_ITEM:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.endRotatingItem(state, action.x, action.y).updatedState;

    case END_ROTATING_ITEM_3D:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.endRotatingItem3D(state, action.x, action.y).updatedState;

    case REPLACE_SUBMODULE:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.replaceSubmodule(state, action.url).updatedState;
    case ANIMATE_OBJECT:
      // state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) });
      return Item.animateObject(state, action.value).updatedState;
    case REMOVE_REPLACE_SUBMODULE:
      return Item.removeReplacingSupport(state).updatedState;
    case ITEM_MOVE_UP:
      return Item.updateItemsAltitude(state, action.layerID, action.itemID, action.value).updatedState;
    case SELECT_TOOL_DRAWING_ITEM_3D:
      return Item.selectToolDrawingItem3D(state, action.sceneComponentType).updatedState;
    case SET_DOOR_STYLE:
      return Item.setDoorStyle(state, action.doorStyle, action.pathes, action.isAll).updatedState;
    case SET_HANDLE_MATERIAL:
      return Item.setHandleMaterial(state, action.material).updatedState;
    case SET_DOOR_HANDLE:
      return Item.setDoorHandle(state, action.doorHandle).updatedState;
    case SET_COUNTER_TOP:
      return Item.setCounterTop(state, action.counterTop).updatedState;
    case SET_MOLDING:
      return Item.setMolding(state, action.molding).updatedState;
    case SET_LINEAR:
      return Item.setLinear(state, action.linear).updatedState;
    case SET_WALL_COLOR:
      state = state.merge({ sceneHistory: history.historyPush(state.sceneHistory, state.scene) }); // save history when this actino performed,so undo enable
      return Item.setWallColor(state, action.wallColor).updatedState;
    case SET_MODELLING:
      return Item.setModelling(state, action.model).updatedState;
    case SET_BACKSPLASH:
      return Item.setBacksplash(state, action.backsplash).updatedState;
    case SET_BACKSPLASH_VISIBLE:
        return Item.setBacksplashVisible(state, action.itemID, action.backsplashVisible).updatedState;
    case SET_APPLIANCE_MATERIAL:
      return Item.setApplianceMaterial(state, action.material).updatedState;
    case SET_INITIAL_DOOR_STYLE:
      return Item.setInitialDoorStyle(state, action.doorStyle, action.oStyle).updatedState;

    case UPDATE_ITEM_POSITION:
      return Item.updateItemPosition(state, action.layerID, action.itemID, action.pos).updatedState;
    // case SET_MOVE_STATUS:
    //   return state.merge({ moveStatus: action.status });
    // case SET_ROTATE_STATUS:
    //   return state.merge({ rotateStatus: action.status });
    default:
      return state;
  }
}
