import convert from 'convert-units';
import { Layer, Group, Hole } from './export';
import {
  IDBroker,
  NameGenerator,
  GeometryUtils
} from '../utils/export';
import { Map, fromJS } from 'immutable';
import path from 'path';
import {
  MODE_IDLE,
  MODE_IDLE_3D,
  MODE_DRAWING_ITEM,
  MODE_DRAWING_ITEM_3D,
  MODE_DRAGGING_ITEM,
  MODE_DRAGGING_ITEM_3D,
  MODE_ROTATING_ITEM,
  MODE_ROTATING_ITEM_3D
} from '../constants';
import { isUndefined } from 'util';
import { samePoints } from '../utils/geometry';
let allItemRect;
let allItemSnap;
let allLines;
let allLineRects;
let allLineSnap;
let allRect;

class Item {
  static create(state, layerID, type, x, y, width, height, rotation, isDuplication) {

    let itemID = IDBroker.acquireID();

    let item = state.catalog.factoryElement(type, {
      id: itemID,
      itemID: state.catalog.getIn(['elements', type, 'itemID']),
      name: NameGenerator.generateName('items', state.catalog.getIn(['elements', type, 'info', 'title'])),
      sku_number: state.catalog.getIn(['elements', type, 'obj']).toJS().sku_number,
      //style: state.catalog.getIn(['elements', type, 'info', 'door']),
      category: state.catalog.getIn(['elements', type, 'type']),
      layoutpos: state.catalog.getIn(['elements', type, 'info', 'layoutpos']),
      cabinet_category: state.catalog.getIn(['elements', type, "obj"]).toJS().cabinet_category,
      type,
      height,
      width,
      x,
      y,
      rotation,
      isDuplication
    }, null, state);

    if (state.doorStyle !== null && state.doorStyle !== undefined) {
      var layer = state.getIn(["scene", "layers", layerID]);
      var temp = layer.doorStyle?layer.doorStyle:state.doorStyle.toJS();
      var cds = temp.doorStyles.cds.find(elem => elem.itemID === state.catalog.getIn(['elements', type, 'itemID']));
      var tmpDS = temp;
      if (cds !== undefined) {
        tmpDS.doorStyles = { ...temp.doorStyles, ...cds.data[0], cabinet_door_style_id: cds.cabinet_door_style_id };
        item = item.merge({
          doorStyle: fromJS(tmpDS)
        });
      }
      else {
        for (let x in state.oStyle.toJS().oStyle) {
          cds = state.oStyle.toJS().oStyle[x].data.doorStyles.cds.find(elem => elem.itemID === state.catalog.getIn(['elements', type, 'itemID']));
          if (cds !== null && cds !== undefined && cds) {
            var tmpDS = state.oStyle.toJS().oStyle[x].data;
            tmpDS.doorStyles = { ...tmpDS.doorStyles, ...cds.data[0], cabinet_door_style_id: cds.cabinet_door_style_id };
            item = item.merge({
              doorStyle: fromJS(tmpDS)
            });
            break;
          }
        }
      }
    }
    else {
      for (let x in state.oStyle.toJS().oStyle) {
        cds = state.oStyle.toJS().oStyle[x].data.doorStyles.cds.find(elem => elem.itemID === state.catalog.getIn(['elements', type, 'itemID']));
        if (cds !== null && cds !== undefined && cds) {
          var tmpDS = state.oStyle.toJS().oStyle[x].data;
          tmpDS.doorStyles = { ...tmpDS.doorStyles, ...cds.data[0], cabinet_door_style_id: cds.cabinet_door_style_id };
          item = item.merge({
            doorStyle: fromJS(tmpDS)
          });
          break;
        }
      }
    }
    if (item.get("type") === "cabinet" && item.get("doorStyle").size === 0) {
      return { updatedState: state, item: null };
    }

    let setSizeOfItemByDoorStyle = () => {
      let doorStyle = item.get('doorStyle').toJS();
      var cds = doorStyle.doorStyles.cds.find(elem => elem.itemID === item.itemID);
      let euro_cds = [];
      if (cds !== undefined) {
        euro_cds = cds && cds.data.filter(element => element.is_euro_cds);
      }
      if (doorStyle.door_style_name === "Euro & Frameless" && euro_cds.length > 0) {
        let newProperties = item.get('properties').toJS()
        if (newProperties.hasOwnProperty('depth')) {
          if (!newProperties.hasOwnProperty('oldDepth')) {
            newProperties['oldDepth'] = new Map({
              length: newProperties.depth.length,
              _length: newProperties.depth._length,
              _unit: newProperties.depth._unit
            });
          }
          newProperties['depth'] = new Map({
            length: convert(euro_cds[0].euro_length).from('in').to('cm'),
            _length: euro_cds[0].euro_length,
            _unit: 'in'
          });
        }
        if (newProperties.hasOwnProperty('height')) {
          if (!newProperties.hasOwnProperty('oldHeight')) {
            newProperties['oldHeight'] = new Map({
              length: newProperties.height.length,
              _length: newProperties.height._length,
              _unit: newProperties.height._unit
            });
          }
          newProperties['height'] = new Map({
            length: convert(euro_cds[0].euro_height).from('in').to('cm'),
            _length: euro_cds[0].euro_height,
            _unit: 'in'
          });
        }
        if (newProperties.hasOwnProperty('width')) {
          if (!newProperties.hasOwnProperty('oldWidth')) {
            newProperties['oldWidth'] = new Map({
              length: newProperties.width.length,
              _length: newProperties.width._length,
              _unit: newProperties.width._unit
            });
          }
          newProperties['width'] = new Map({
            length: convert(euro_cds[0].euro_width).from('in').to('cm') - 10,
            _length: euro_cds[0].euro_width,
            _unit: 'in'
          });
        }
        item = item.merge({properties: fromJS(newProperties)});
      } else {
        let properties = item.get('properties').toJS();
        if (properties.hasOwnProperty('oldDepth')) {
          properties['depth'] = new Map({
            length: properties.oldDepth.length,
            _length: properties.oldDepth._length,
            _unit: properties.oldDepth._unit
          });
        }
        if (properties.hasOwnProperty('oldHeight')) {
          properties['height'] = new Map({
            length: properties.oldHeight.length,
            _length: properties.oldHeight._length,
            _unit: properties.oldHeight._unit
          });
        }
        if (properties.hasOwnProperty('oldWidth')) {
          properties['width'] = new Map({
            length: properties.oldWidth.length,
            _length: properties.oldWidth._length,
            _unit: properties.oldWidth._unit
          });
        }
        item = item.merge({properties: fromJS(properties)});
      }
    }
    item.category==='cabinet' && setSizeOfItemByDoorStyle();

    item = this.updateDoorHandle(item, state.getIn(['scene', 'layers', layerID]));

    state = state.setIn(['scene', 'layers', layerID, 'items', itemID], item);

    if (item.type.includes('Light')) {
      let ceilHeight = state.getIn(['scene', 'layers', layerID, 'ceilHeight']);
      let ceilUnit = state.getIn(['scene', 'layers', layerID, 'unit']);
      ceilHeight = convert(ceilHeight).from(ceilUnit).to('cm');
      let newAltitude = ceilHeight - item.properties.getIn(['height', 'length']);
      newAltitude = convert(newAltitude).from('cm').to(ceilUnit);
      item = item.setIn(['properties', 'altitude', '_length'], newAltitude);
      state = state.setIn(['scene', 'layers', layerID, 'items', item.id], item);
    }
    return { updatedState: state, item };
  }

  static updateDoorHandle(item, layer) {
    const doorHandle = layer.get('doorHandle');

    if(doorHandle == "")
      return item;
    const dataJSON = item.toJS();
    let itemID = dataJSON.id;
    var doorStyle = dataJSON.doorStyle
    var tmpDS = {
      ...doorStyle,
      handle_gltf: doorHandle,
      metalness: 0.2, roughness: 0.1
    }
    let cnt = 0;
    for (let prop in doorStyle.doorStyles) cnt++;
    if (cnt !== 0) {
      for (var i = 1; i < 10; i++) {
        tmpDS["doorStyles"]["door_handle_" + i + "_gltf"] = doorHandle;
        tmpDS["doorStyles"]["fixed_drawer_door_handle_" + i + "_gltf"] = doorHandle;
        tmpDS["doorStyles"]["drawer_door_handle_" + i + "_gltf"] = doorHandle;
      }
    }

    item = item.mergeIn(["doorStyle"], fromJS(tmpDS));

    return item;
  }

  static select(state, layerID, itemID) {
    state = Layer.select(state, layerID).updatedState;
    state = Layer.selectElement(state, layerID, 'items', itemID).updatedState;
    state = state.merge({
      replacingSupport: new Map({
        layerID,
        itemID,
      })

    });

    return { updatedState: state };
  }

  static selectHole(state, layerID, holeID) {
    state = Layer.select(state, layerID).updatedState;
    state = Layer.selectElement(state, layerID, 'holes', holeID).updatedState;

    return { updatedState: state };
  }

  static editWidth(state, newWidth, layerID, itemID) {
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let properties = item.properties;
    if (newWidth === 0) {
      alert('You can not set width to 0');
      return { updatedState: state };
    }
    let width = new Map({
      _length: newWidth,
      _unit: 'in',
      length: convert(newWidth).from('in').to('cm')
    })
    properties = properties.set('width', width);
    state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, 'properties'], properties);
    return { updatedState: state };
  }

  static prepareSnap(state, layerID) {
    let layer = state.getIn(['scene', 'layers', layerID]);
    let scene = state.get('scene');
    let catalog = state.catalog.toJS();
    allLines = GeometryUtils.getAllLines(layer);
    allLineRects = GeometryUtils.buildRectFromLines(layer, allLines);
    allItemRect = GeometryUtils.getAllItems(scene, catalog, allLineRects);
    allItemSnap = GeometryUtils.getAllItemSnap(allItemRect);
    allLineSnap = GeometryUtils.getAllLineSnap(allLineRects, allItemRect.cur);
    allRect = allItemRect.others.concat(allLineRects);
    allItemSnap = GeometryUtils.validateSnaps(allItemSnap, allRect);
    allLineSnap = GeometryUtils.validateSnaps(allLineSnap, allRect);
  };

  static duplicateSelected(state, currentObject) {
    let layerID = state.getIn(['scene', 'selectedLayer']);
    let layer = state.getIn(['scene', 'layers', layerID]);
    let objectID = currentObject.id;
    let myObject;
    let x = currentObject.x;
    let y = currentObject.y;
    let sceneComponentType, width, depth, rotRad;
    switch (currentObject.prototype) {
      case "items":
        myObject = state.getIn(['scene', 'layers', layerID, 'items', objectID]);
        sceneComponentType = myObject.type;
        width = myObject.properties.getIn(['width', 'length']);
        depth = myObject.properties.getIn(['depth', 'length']);
        rotRad = myObject.rotation * Math.PI / 180;
        this.prepareSnap(state, layerID);
        let val = { pos: { x: x + width * Math.cos(rotRad), y: y + width * Math.sin(rotRad) }, rotRad: rotRad };
        val.size = allItemRect.cur.size;
        val.layoutpos = allItemRect.cur.layoutpos;
        val.is_corner = allItemRect.cur.is_corner;
        let isrectSect = GeometryUtils.validInterSect(allRect, val);
        if (isrectSect) {
          let { updatedState: stateI, item } = this.create(state, layerID, sceneComponentType, x + width * Math.cos(rotRad), y + width * Math.sin(rotRad), 200, 100, rotRad * 180 / Math.PI, true);
          if (item === null) {
            alert("There are no Door Colors in this cabinet.");
            state = state.merge({
              mode: MODE_IDLE,
              drawingSupport: new Map(),
            });
          }
          else {
            state = Item.select(stateI, layerID, item.id).updatedState;
            state = state.setIn(['drawingSupport', 'currentID'], item.id);
          }
        } 
        else {
          let val = { pos: { x: x - width * Math.cos(rotRad), y: y - width * Math.sin(rotRad) }, rotRad: rotRad };
          val.size = allItemRect.cur.size;
          val.layoutpos = allItemRect.cur.layoutpos;
          val.is_corner = allItemRect.cur.is_corner;
          let isRect = GeometryUtils.validInterSect(allRect, val);
          if (isRect) {
            let { updatedState: stateI, item } = this.create(state, layerID, sceneComponentType, x - width * Math.cos(rotRad), y - width * Math.sin(rotRad), 200, 100, rotRad * 180 / Math.PI, true);
            if (item === null) {
              alert("There are no Door Colors in this cabinet.");
              state = state.merge({
                mode: MODE_IDLE,
                drawingSupport: new Map(),
              });
            }
            else {
              state = Item.select(stateI, layerID, item.id).updatedState;
              state = state.setIn(['drawingSupport', 'currentID'], item.id);
            }
          }
          else{
            let val = { pos: { x: x - depth * Math.sin(rotRad), y: y - depth * Math.cos(rotRad) }, rotRad: rotRad };
            val.size = allItemRect.cur.size;
            val.layoutpos = allItemRect.cur.layoutpos;
            val.is_corner = allItemRect.cur.is_corner;
            let isRectDown = GeometryUtils.validInterSect(allRect, val); 
            if (isRectDown) {
              console.log("there is some space");
              let { updatedState: stateI, item } = this.create(state, layerID, sceneComponentType, x - depth * Math.sin(rotRad), y - depth * Math.cos(rotRad), 200, 100, rotRad * 180 / Math.PI, true);
              if (item === null) {
                alert("There are no Door Colors in this cabinet.");
                state = state.merge({
                  mode: MODE_IDLE,
                  drawingSupport: new Map(),
                });
              }
              else {
                state = Item.select(stateI, layerID, item.id).updatedState;
                state = state.setIn(['drawingSupport', 'currentID'], item.id);
              }
            }
            else{
              let val = { pos: { x: x + depth * Math.sin(rotRad), y: y + depth * Math.cos(rotRad) }, rotRad: rotRad };
              val.size = allItemRect.cur.size;
              val.layoutpos = allItemRect.cur.layoutpos;
              val.is_corner = allItemRect.cur.is_corner;
              let isRectUp = GeometryUtils.validInterSect(allRect, val); 
              if (isRectUp) {
                let { updatedState: stateI, item } = this.create(state, layerID, sceneComponentType, x + depth * Math.sin(rotRad), y + depth * Math.cos(rotRad), 200, 100, rotRad * 180 / Math.PI, true);
                if (item === null) {
                  alert("There are no Door Colors in this cabinet.");
                  state = state.merge({
                    mode: MODE_IDLE,
                    drawingSupport: new Map(),
                  });
                }
                else {
                  state = Item.select(stateI, layerID, item.id).updatedState;
                  state = state.setIn(['drawingSupport', 'currentID'], item.id);
                }
              }
              else {
                console.log("there is no space");
                alert("No more space!");
              }
            } 
          
        }
      }
      break;
      case "holes":
        this.prepareSnap(state, layerID);
        myObject = state.getIn(['scene', 'layers', layerID, 'holes', objectID]);
        let line = state.getIn(['scene', 'layers', layerID, 'lines', myObject.line]);
        sceneComponentType = myObject.type;
        width = myObject.get('properties').get('width').get('length');
        let myOffset = myObject.offset;
        let v0 = layer.getIn(['vertices', line.vertices.get(0)]);
        let v1 = layer.getIn(['vertices', line.vertices.get(1)]);
        let lineLength = GeometryUtils.pointsDistance(v0.x, v0.y, v1.x, v1.y);
        let delta = width / 2 / lineLength + 0.00001;
        let allHoles = state.getIn(['scene', 'layers', layerID, 'holes']);
        let rightAble = true;
        let leftAble = true;
        if(myOffset + 3 * delta > 1) rightAble = false;
        else if (myOffset - 3 * delta < 0) leftAble = false;
        if (allHoles.size === 0) {
          let { updatedState: stateH, hole } = this.createHole(state, layerID, sceneComponentType, myObject.line, myOffset + delta * 2, myObject.get('properties'));
          state = this.selectHole(stateH, layerID, hole.id).updatedState;
          state = state.setIn(['drawingSupport', 'currentID'], hole.id);
        } else {
          allHoles.forEach( hole => {
            if (hole.line === line.id)
              if (!hole.selected) {
                let newDelta = hole.get('properties').get('width').get('length') / 2 /lineLength;
                if (myOffset < hole.offset) {
                  if (myOffset + delta * 3 > hole.offset - newDelta) {
                    rightAble = false;
                  }
                } else {
                  if (myOffset - delta * 3 < hole.offset + newDelta) {
                    leftAble = false;
                  }
                }
              }
          });
        }
        if (rightAble) {
          let { updatedState: stateH, hole } = this.createHole(state, layerID, sceneComponentType, myObject.line, myOffset + delta * 2, myObject.get('properties'));
          state = this.selectHole(stateH, layerID, hole.id).updatedState;
          state = state.setIn(['drawingSupport', 'currentID'], hole.id);
        } else {
          if (leftAble) {
            let { updatedState: stateH, hole } = this.createHole(state, layerID, sceneComponentType, myObject.line, myOffset - delta * 2, myObject.get('properties'));
            state = this.selectHole(stateH, layerID, hole.id).updatedState;
            state = state.setIn(['drawingSupport', 'currentID'], hole.id);
          } else alert("No more space");
        }
        break;
      case "lines":
        alert("Duplicating lines is not currently supported yet.");
        break;
      default: break;
    }
    return { updatedState: state };
  }

  static storeDistArray(state, layerID, itemID, distArray){
    let curDistArray = state.getIn(['scene','layers',layerID,'items',itemID,'distArray']);
    let isEqualDist = (a, b) => {
      return (a == b);
    }
    if(curDistArray) {
      let equal = curDistArray.every((curElement, index) => {
        return isEqualDist(curElement[0],distArray[index][0]);
      });
      if(!equal) {
        state = state.setIn(['scene', 'layers', layerID, 'items', itemID, 'distArray'], distArray);
      }
    }
    
    return { updatedState: state };
  }

  static validateItemPositions(state, layerID) {
    let layer = state.getIn(['scene', 'layers', layerID]);
    
    function isItemInRect(item) {
      let { width, height } = state.getIn(['scene']);
      return item.x>=0&&item.x<=width&&item.y>=0&&item.y<=height;
    }

    layer.items.forEach(item => {
      if(!isItemInRect(item))
        state = this.remove(state, layerID, item.id).updatedState;
    })
    return { updatedState: state };
  }

  static remove(state, layerID, itemID) {
    state = this.unselect(state, layerID, itemID).updatedState;
    state = Layer.removeElement(state, layerID, 'items', itemID).updatedState;

    state.getIn(['scene', 'groups']).forEach(group => state = Group.removeElement(state, group.id, layerID, 'items', itemID).updatedState);

    return { updatedState: state };
  }

  static unselect(state, layerID, itemID) {
    state = Layer.unselect(state, layerID, 'items', itemID).updatedState;
    return { updatedState: state };
  }

  static selectToolDrawingItem(state, sceneComponentType) {
    state = state.merge({
      mode: MODE_DRAWING_ITEM,
      drawingSupport: new Map({
        type: sceneComponentType
      })
    });

    return { updatedState: state };
  }

  static selectToolDrawingItem3D(state, sceneComponentType) {
    state = state.merge({
      mode: MODE_DRAWING_ITEM_3D,
      drawingSupport: new Map({
        type: sceneComponentType
      })
    });
    state = state.mergeIn(['scene', 'loadFlag'], false);
    return { updatedState: state };
  }

  static endCreatingCabinet(state) {
    state = state.merge({
      isOpen: false,
      openedType: 0,
    });
    return { updatedState: state };
  }
  static updatePopupOpen(state, value) {
    state = state.merge({
      openedType: value,
    });
    return { updatedState: state };
  }

  static updateDrawingItem(state, layerID, x, y) {

    if (state.hasIn(['drawingSupport', 'currentID'])) {
      let mode = state.get('mode');
      if ([MODE_DRAWING_ITEM_3D].includes(mode)) {
        state = state.updateIn(['scene', 'layers', layerID, 'items', state.getIn(['drawingSupport', 'currentID'])], item => item.merge({ x, y }));
        state = state.merge({ mode: MODE_IDLE_3D });
      }
      if ([MODE_DRAWING_ITEM].includes(mode)) {
        state = state.merge({ mode: MODE_IDLE });
        state = state.updateIn(['scene', 'layers', layerID, 'items', state.getIn(['drawingSupport', 'currentID'])], item => item.merge({ x, y }));
      }
    }
    else {
      let { updatedState: stateI, item } = this.create(state, layerID, state.getIn(['drawingSupport', 'type']), x, y, 200, 100, 0, false);
      if (item === null) {
        alert("There are no Door Colors in this cabinet.");
        state = state.merge({
          mode: MODE_IDLE,
          drawingSupport: new Map(),
        });
      }
      else {
        state = Item.select(stateI, layerID, item.id).updatedState;
        state = state.setIn(['drawingSupport', 'currentID'], item.id);
      }
    }

    return { updatedState: state };
  }

  static updateDraggingItemChanged(state, x, y, layerID, itemID) {
    let { scene } = state;
    // let originalX = draggingSupport.get('originalX');
    // let originalY = draggingSupport.get('originalY');

    // let diffX = x;
    // let diffY = y;

    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    // let tX = originalX - diffX;
    // let tY = originalY - diffY;
    // tX = tX > 500 ? 500 : tX < 0 ? 0 : tX;
    // tY = tY > 500 ? 500 : tY < 0 ? 0 : tY;
    item = item.merge({
      x: x,
      y: y,
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item),

    });

    return { updatedState: state };
  }

  static endDrawingItem(state, layerID, x, y) {
    let catalog = state.catalog;
    state = this.updateDrawingItem(state, layerID, x, y, catalog).updatedState;
    state = Layer.unselectAll(state, layerID).updatedState;
    let popup = state.get('popup');

    state = state.merge({
      drawingSupport: Map({
        type: state.drawingSupport.get('type')
      }),
      isOpen: !popup
    });

    state = Layer.unselectAll(state, layerID).updatedState;
    return { updatedState: state };
  }

  static beginDraggingItem(state, layerID, itemID, x, y) {

    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);

    if (isUndefined(item))
      return;
    state = state.merge({
      mode: MODE_DRAGGING_ITEM,
      draggingSupport: Map({
        layerID,
        itemID,
        startPointX: x,
        startPointY: y,
        originalX: item.x,
        originalY: item.y
      })
    });

    return { updatedState: state };
  }

  static beginDraggingItem3D(state, layerID, itemID, x, y) {

    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    if (isUndefined(item))
      return { updatedState: state };
    state = state.merge({
      mode: MODE_DRAGGING_ITEM_3D,
      draggingSupport: Map({
        layerID,
        itemID,
        startPointX: x,
        startPointY: y,
        originalX: item.x,
        originalY: item.y
      })
    });

    return { updatedState: state };
  }

  static updateDraggingItem(state, x, y) {
    let { draggingSupport, scene } = state;

    let layerID = draggingSupport.get('layerID');
    let itemID = draggingSupport.get('itemID');
    let startPointX = draggingSupport.get('startPointX');
    let startPointY = draggingSupport.get('startPointY');
    let originalX = draggingSupport.get('originalX');
    let originalY = draggingSupport.get('originalY');

    let diffX = startPointX - x;
    let diffY = startPointY - y;

    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    if (isUndefined(item))
      return { updatedState: state };
    let tX = originalX - diffX;
    let tY = originalY - diffY;
    tX = tX > scene.width ? scene.width : tX < 0 ? 0 : tX;
    tY = tY > scene.height ? scene.height : tY < 0 ? 0 : tY;
    item = item.merge({
      x: tX,
      y: tY
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });

    return { updatedState: state };
  }

  static updateDraggingItem3DX(state, x) {
    let { draggingSupport, scene } = state;

    let layerID = draggingSupport.get('layerID');
    let itemID = draggingSupport.get('itemID');
    let startPointX = draggingSupport.get('startPointX');
    let originalX = draggingSupport.get('originalX');

    let diffX = startPointX - x;

    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    item = item.merge({
      x: originalX - diffX,
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });

    return { updatedState: state };
  }

  static updateDraggingItem3DY(state, y) {
    let { draggingSupport, scene } = state;

    let layerID = draggingSupport.get('layerID');
    let itemID = draggingSupport.get('itemID');
    let startPointY = draggingSupport.get('startPointY');
    let originalY = draggingSupport.get('originalY');

    let diffY = startPointY - y;

    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    item = item.merge({
      y: originalY - diffY,
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });

    return { updatedState: state };
  }

  static endDraggingItem(state, x, y) {
    state = this.updateDraggingItem(state, x, y).updatedState;
    state = state.merge({ mode: MODE_IDLE });

    return { updatedState: state };
  }

  static endDraggingItem3D(state) {
    // state = this.updateDraggingItem(state, x, y).updatedState;
    state = state.merge({ mode: MODE_IDLE_3D });

    return { updatedState: state };
  }

  static beginRotatingItem(state, layerID, itemID, x, y) {

    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let originRotation = item.get('rotation');

    state = state.merge({
      mode: MODE_ROTATING_ITEM,
      rotatingSupport: Map({
        layerID,
        itemID,
        x,
        y,
        originRotation
      })
    });

    return { updatedState: state };
  }

  static beginRotatingItem3D(state, layerID, itemID, x, y) {

    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let originRotation = item.get('rotation');

    state = state.merge({
      mode: MODE_ROTATING_ITEM_3D,
      rotatingSupport: Map({
        layerID,
        itemID,
        x,
        y,
        originRotation
      })
    });

    return { updatedState: state };
  }

  static updateRotatingItem(state, x, y) {
    let { rotatingSupport, scene } = state;
    let layerID = rotatingSupport.get('layerID');
    let itemID = rotatingSupport.get('itemID');
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let origin = {
      x: rotatingSupport.get('x'),
      y: rotatingSupport.get('y'),
      originRotation: rotatingSupport.get('originRotation')
    }

    let newRot = Math.atan2(origin.y - item.y, origin.x - item.x) * 180 / Math.PI + 90;
    let deltaRot = newRot - origin.originRotation;

    let deltaX = x - item.x;
    let deltaY = y - item.y;

    let rotation = Math.atan2(deltaY, deltaX) * 180 / Math.PI + 90;
    rotation -= deltaRot;
    if (-5 < rotation && rotation < 5) rotation = 0;
    if (-95 < rotation && rotation < -85) rotation = -90;
    if (-185 < rotation && rotation < -175) rotation = -180;
    if (85 < rotation && rotation < 95) rotation = 90;
    if (-270 < rotation && rotation < -265) rotation = 90;
    if (175 < rotation && rotation < 185) rotation = 180;
    if (265 < rotation && rotation < 275) rotation = -90;

    item = item.merge({
      rotation,
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });

    return { updatedState: state };
  }

  static updateRotatingItemChanged(state, rotation, layerID, itemID) {
    let scene = state.scene;

    // let originalX = draggingSupport.get('originalX');
    // let originalY = draggingSupport.get('originalY');

    // let diffX = x;
    // let diffY = y;

    let item = scene.getIn(['layers', layerID, 'items', itemID]);
    // let tX = originalX - diffX;
    // let tY = originalY - diffY;
    // tX = tX > 500 ? 500 : tX < 0 ? 0 : tX;
    // tY = tY > 500 ? 500 : tY < 0 ? 0 : tY;
    item = item.merge({
      rotation: rotation
    });

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });

    return { updatedState: state };
  }

  static endRotatingItem(state, x, y) {
    //state = this.updateRotatingItem(state, x, y).updatedState;
    state = state.merge({ mode: MODE_IDLE });

    return { updatedState: state };
  }

  static endRotatingItem3D(state, x, y) {
    state = this.updateRotatingItem(state, x, y).updatedState;
    state = state.merge({ mode: MODE_IDLE_3D });

    return { updatedState: state };
  }

  static replaceSubmodule(state, url) {
    let { replacingSupport, scene } = state;
    url = 'catalog/texture.png';
    let urlData = url.split('/');
    let newURL = "";
    let addURL = false;
    for (let i = 0; i < urlData.length; i++) {
      if (urlData[i] == "") {
        continue;
      }
      newURL += '/' + urlData[i];
    }
    let layerID = replacingSupport.get('layerID');
    let itemID = replacingSupport.get('itemID');
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let submodule = [];
    let normalMap = [];

    for (let i = 0; i < item.submodule.size; i++)
      submodule.push(item.submodule.get(i));
    for (let i = 0; i < item.normalMap.size; i++)
      normalMap.push(item.normalMap.get(i));
    //let submodule = state.getIn(['scene', 'layers', layerID, 'items', itemID, 'submodule']);
    urlData = newURL.split('/');
    let flag = false;
    if (urlData[urlData.length - 1].includes(".gltf")) {
      for (let i = 0; i < submodule.length; i++) {
        let pathData = submodule[i].split('/');
        if (pathData[pathData.length - 2] === urlData[urlData.length - 2]) {
          submodule[i] = newURL;
          flag = true;
        }
      }
      if (!flag)
        submodule.push(newURL);
      item = item.merge({
        submodule
      });
    } else {
      for (let i = 0; i < normalMap.length; i++) {
        let pathData = normalMap[i].split('/');
        if (pathData[pathData.length - 2] === urlData[urlData.length - 2]) {
          normalMap[i] = newURL;
          flag = true;
        }
      }
      if (!flag)
        normalMap.push(newURL);

      item = item.merge({
        normalMap
      });
    }

    state = state.merge({
      scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
    });
    state = state.merge({ mode: MODE_IDLE_3D });

    return { updatedState: state };
  }

  static setProperties(state, layerID, itemID, properties) {
    state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, 'properties'], properties);

    return { updatedState: state };
  }

  static setJsProperties(state, layerID, itemID, properties) {
    return this.setProperties(state, layerID, itemID, fromJS(properties));
  }

  static updateProperties(state, layerID, itemID, properties) {
    properties.forEach((v, k) => {
      if (state.hasIn(['scene', 'layers', layerID, 'items', itemID, 'properties', k]))
        state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, 'properties', k], v);
    });

    return { updatedState: state };
  }

  static updateJsProperties(state, layerID, itemID, properties) {
    return this.updateProperties(state, layerID, itemID, fromJS(properties));
  }

  static setAttributes(state, layerID, itemID, itemAttributes) {
    state = state
    .mergeIn(['scene', 'layers', layerID, 'items', itemID], itemAttributes);
    return { updatedState: state };
  }

  static setJsAttributes(state, layerID, itemID, itemAttributes) {
    itemAttributes = fromJS(itemAttributes);
    return this.setAttributes(state, layerID, itemID, itemAttributes);
  }

  static animateObject(state, value) {
    let { replacingSupport, scene } = state;
    let layerID = replacingSupport.get('layerID');
    let itemID = replacingSupport.get('itemID');
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    if (item !== undefined) {
      let animCount = item.get('animate');
      item = item.merge({
        animValue: value,
        animate: animCount + 1
      });

      state = state.merge({
        scene: scene.mergeIn(['layers', layerID, 'items', itemID], item)
      });
      state = state.merge({ mode: MODE_IDLE_3D });
    }

    return { updatedState: state };
  }

  static removeReplacingSupport(state) {
    state = state.merge({
      replacingSupport: new Map()
    });
    return { updatedState: state };
  }


  static setInitialDoorStyle(state, doorStyle, oStyle) {
    if (doorStyle === undefined) {
      state = state.merge({
        oStyle: new Map({
          oStyle
        })
      });
    }
    else {
      state = state.merge({
        doorStyle: (doorStyle),
        oStyle: new Map({
          oStyle
        })
      });
      let { scene } = state;
      let layerID = scene.get('selectedLayer');
      let layers = scene.layers.get(layerID);
      let items = layers.items;
      items.forEach(data => {
        {
          let itemID = data.id;

          var tmpDS = {
            ...data.doorStyle,
            doorStyles: {
              ...data.doorStyle.doorStyles,
              counttop: doorStyle.doorStyles.counttop,
            }
          }
          data = data.mergeIn(["doorStyle"], fromJS(tmpDS));
          layers = layers.mergeIn(["items", itemID], data)
          state = state.merge({
            scene: scene.mergeIn(['layers', layerID], layers)
          });
        }
      })
    }
    return { updatedState: state };
  }

  static setDoorStyle(state, doorStyle, pathes, isAll) {
    var keys = Object.keys(doorStyle.doorStyles);
    keys = keys.filter(elem => !elem.endsWith("_gltf"));
    var tmp = {};
    for (let x in keys) {
      tmp[keys[x]] = doorStyle.doorStyles[keys[x]];
    }
    state = state.setIn (["scene", "layers", state.scene.selectedLayer, "doorStyle"], doorStyle);
    state = state.merge({
      doorStyle: (doorStyle),
    });
    let layerID = state.scene.get('selectedLayer');
    let itemIDs = state.scene.layers.getIn([layerID, 'selected', 'items']).toJS();

    let setDoorStyleOfItem = (data) => {
      if(data.category !== 'cabinet') return; //CDS should effect to cabinets
      let itemID = data.id;
      var cds = doorStyle.doorStyles.cds.find(elem => elem.itemID === data.itemID);
      let euro_cds = [];
      if (cds !== undefined) {
        euro_cds = cds && cds.data.filter(element => element.is_euro_cds);
      }
      let cds_data;
      if (doorStyle.door_style_name === "Euro & Frameless" && euro_cds.length > 0) {
        cds_data = cds ? {...euro_cds[0], cabinet_door_style_id: cds.cabinet_door_style_id,} : {};
        let newProperties = state.scene.getIn(['layers', layerID, 'items', itemID, "properties"]).toJS();
        if (newProperties.hasOwnProperty('depth')) {
          if (!newProperties.hasOwnProperty('oldDepth')) {
            newProperties['oldDepth'] = new Map({
              length: newProperties.depth.length,
              _length: newProperties.depth._length,
              _unit: newProperties.depth._unit
            });
          }
          newProperties['depth'] = new Map({
            length: convert(euro_cds[0].euro_length).from('in').to('cm'),
            _length: euro_cds[0].euro_length,
            _unit: 'in'
          });
        }
        if (newProperties.hasOwnProperty('height')) {
          if (!newProperties.hasOwnProperty('oldHeight')) {
            newProperties['oldHeight'] = new Map({
              length: newProperties.height.length,
              _length: newProperties.height._length,
              _unit: newProperties.height._unit
            });
          }
          newProperties['height'] = new Map({
            length: convert(euro_cds[0].euro_height).from('in').to('cm'),
            _length: euro_cds[0].euro_height,
            _unit: 'in'
          });
        }
        if (newProperties.hasOwnProperty('width')) {
          if (!newProperties.hasOwnProperty('oldWidth')) {
            newProperties['oldWidth'] = new Map({
              length: newProperties.width.length,
              _length: newProperties.width._length,
              _unit: newProperties.width._unit
            });
          }
          newProperties['width'] = new Map({
            length: convert(euro_cds[0].euro_width).from('in').to('cm') - 10,
            _length: euro_cds[0].euro_width,
            _unit: 'in'
          });
        }
        state = this.setJsProperties(state, layerID, itemID, newProperties).updatedState;
      } else {
        cds_data = cds ? {...cds.data[0], cabinet_door_style_id: cds.cabinet_door_style_id,} : {};
        let properties = state.scene.getIn(['layers', layerID, 'items', itemID, "properties"]).toJS();
        if (properties.hasOwnProperty('oldDepth')) {
          properties['depth'] = new Map({
            length: properties.oldDepth.length,
            _length: properties.oldDepth._length,
            _unit: properties.oldDepth._unit
          });
        }
        if (properties.hasOwnProperty('oldHeight')) {
          properties['height'] = new Map({
            length: properties.oldHeight.length,
            _length: properties.oldHeight._length,
            _unit: properties.oldHeight._unit
          });
        }
        if (properties.hasOwnProperty('oldWidth')) {
          properties['width'] = new Map({
            length: properties.oldWidth.length,
            _length: properties.oldWidth._length,
            _unit: properties.oldWidth._unit
          });
        }
        state = this.setJsProperties(state, layerID, itemID, properties).updatedState;
      }
      var tmpDS = {
        id: doorStyle.id,
        brightness: doorStyle.brightness,
        color: doorStyle.color,
        glossness: doorStyle.glossness,
        name: doorStyle.name,
        door_style_name: doorStyle.door_style_name,
        texture: doorStyle.texture,
        thumbnail: doorStyle.thumbnail,
        install: doorStyle.install,
        type: doorStyle.type,
        sku: doorStyle.sku,
        isAssembled: doorStyle.isAssembled,
        doorStyles: {
          ...tmp,
          ...cds_data
        }
      };
      state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, "doorStyle"], fromJS(tmpDS));
    }

    if (isAll) {
      let items = state.scene.layers.get(layerID).items;
      items.forEach(setDoorStyleOfItem);
    } else {
      if (itemIDs.length > 0) {
        for (let i = 0; i < itemIDs.length; i++) {
          let itemID = itemIDs[i];
          let items = state.scene.layers.get(layerID).items;
          items.forEach(data => {
            if (itemID === data.id) {
              setDoorStyleOfItem(data);
            }
          });
        }
      }
    }
    
    return { updatedState: state };
  }
  static setCounterTop(state, counterTop) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layers = scene.layers.get(layerID);
    layers = layers.mergeIn(["counterTop"], counterTop);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layers)
    });
    let items = layers.items;
    items.forEach(data => {
      {
        const dataJSON = data.toJS();
        let itemID = dataJSON.id;
        let newCounter = dataJSON.counterTop;
        newCounter = { ...newCounter, ...counterTop };
        data = data.mergeIn(["counterTop"], newCounter);
        layers = layers.mergeIn(["items", itemID], data);
        state = state.merge({
          scene: scene.mergeIn(['layers', layerID], layers)
        });
      }
    })

    return { updatedState: state };
  }

  static setItemsDoorHandle (state, scene, layer, layerID, elements_item) {
    elements_item.forEach(data => {
      let itemID = data.id;
      let item = this.updateDoorHandle(data, layer);
      layer = layer.mergeIn(["items", itemID], item)
      state = state.merge({
        scene: scene.mergeIn(['layers', layerID], layer)
      });
    });
    return { updatedState: state };
  }

  static setDoorHandle(state, doorHandle) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);

    layer = layer.mergeIn(['doorHandle'], doorHandle);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });

    let items = layer.items;
    let selectedItems = items.filter ((element) => element.selected === true); // find selected items (default 1 selected)

    if (selectedItems.size === 0) {
      state = this.setItemsDoorHandle (state, scene, layer, layerID, items).updatedState;
    } else {
      state = this.setItemsDoorHandle (state, scene, layer, layerID, selectedItems).updatedState;
    }
    return { updatedState: state };
  }
  static setHandleMaterial(state, material) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layers = scene.layers.get(layerID);
    let items = layers.items;
    items.forEach(data => {
      const dataJSON = data.toJS();
      let itemID = dataJSON.id;
      var doorStyle = dataJSON.doorStyle
      var tmpDS = {
        ...doorStyle,
        metalness: material.metalness,
        roughness: material.roughness,
      }
      data = data.mergeIn(["doorStyle"], fromJS(tmpDS));
      layers = layers.mergeIn(["items", itemID], data)
      state = state.merge({
        scene: scene.mergeIn(['layers', layerID], layers)
      });
    })
    return { updatedState: state };
  }

  static setWallColor(state, wallColor) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);

    layer = layer.merge({ wallColor });
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });

    let lines = layer.lines;
    lines.forEach(data => {
      const dataJSON = data.toJS();
      let lineID = dataJSON.id;
      data = data.mergeIn(["wallColor"], wallColor);
      layer = layer.mergeIn(["lines", lineID], data);
      state = state.merge({
        scene: scene.mergeIn(['layers', layerID], layer)
      });
    });
    return { updatedState: state };
  }
  static setBacksplash(state, backsplash) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);
    const dataJSON = layer.toJS();
    let newBack = dataJSON.backsplash;
    newBack = { ...newBack, ...backsplash };
    layer = layer.mergeIn(['backsplash'], newBack)
    layer = layer.set('backsplashApplied', true);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });
    return { updatedState: state };
  }

  static setMolding(state, molding) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);
    let temp = layer.molding;
    let flag = temp.some(mol => (mol.name === molding.name));
    if (flag) {
      temp = temp.filter(mol => (mol.name !== molding.name));
    } else {
      temp = temp.concat(molding);
    }
    layer = layer.set("molding", temp);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });
    return { updatedState: state };
  }

  static setLinear(state, linear) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);
    let temp = layer.linears;
    let flag = false;
    temp = temp.filter(li => {
      if(linear.items.length !== li.items.length || linear.lines.length !== li.lines.length){
        return true;
      }
      let i = 0, j = 0;
      linear.items.forEach(item=>{
        if(li.items.findIndex(a=>a.id === item.id) > -1){
          i++;
        }
      });
      linear.lines.forEach(line=>{
        if(li.lines.findIndex(a=>{
            if((samePoints(a[0], line[0]) && samePoints(a[1], line[1])) || (samePoints(a[1], line[0]) && samePoints(a[0], line[1]))){
              return true;
            }
            return false;
          }) > -1
        ){
          j++;
        }
      });
      if(j === linear.lines.length && i === linear.items.length){
        if(linear.molding.name === li.molding.name){
          return false;
        }else{
          if(linear.molding.location_type === li.molding.location_type){
            flag = true;
            return false;
          }
        }
        return true;
      }
      return true;
    })
    if(temp.length === layer.linears.length || flag){
      temp = temp.concat(linear);
    }
    layer = layer.set("linears", temp);
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });
    return { updatedState: state };
  }
  
  static setBacksplashVisible(state, itemID, backsplashVisible) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layer = scene.layers.get(layerID);
    layer = layer.mergeIn(["items", itemID, "backsplashVisible"], backsplashVisible);
    // let cabinet_category = layer.getIn(["items"], itemID).toJS()[itemID].cabinet_category;
    // if (cabinet_category && (cabinet_category.search("Sink") !== -1 || cabinet_category.search("End/Angle") !== -1)) {
    //   layer = layer.mergeIn(["items", itemID, "backsplashVisible"], true);
    // } else {
    //   layer = layer.mergeIn(["items", itemID, "backsplashVisible"], false);
    // }
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layer)
    });

    return { updatedState: state };
  }
  static setApplianceMaterial(state, material) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layers = scene.layers.get(layerID);
    let items = layers.items;
    items.forEach(data => {
      const dataJSON = data.toJS();
      let itemID = dataJSON.id;
      var newMaterial = {
        metalness: material.metalness,
        roughness: material.roughness,
      }
      data = data.mergeIn(["applianceMaterial"], newMaterial);
      layers = layers.mergeIn(["items", itemID], data)
      state = state.merge({
        scene: scene.mergeIn(['layers', layerID], layers)
      });
    });

    return { updatedState: state };
  }

  static setModelling(state, molding) {
    let { scene } = state;
    let layerID = scene.get('selectedLayer');
    let layers = scene.layers.get(layerID);
    let items = layers.items;
    items.forEach(data => {
      const dataJSON = data.toJS();
      let itemID = dataJSON.id;
    })
    state = state.merge({
      scene: scene.mergeIn(['layers', layerID], layers)
    });
    return { updatedState: state };
  }

  static updateItemsAltitude(state, layerID, itemID, value) {
    let item = state.getIn(['scene', 'layers', layerID, 'items', itemID]);
    let properties = item.get("properties");
    let altitude = properties.get("altitude");
    let length = altitude.get("length");
    let _length = altitude.get("_length");
    let _unit = altitude.get("_unit");
    length = length - value;
    switch (_unit) {
      case 'mm':
        _length = length * 10;
        break;
      case 'm':
        _length = length * 0.01;
        break;
      default:
        _length = length;
    }
    if (length <= 0) {
      length = 0;
      _length = 0;
    }
    state = state.mergeIn(['scene', 'layers', layerID, 'items', itemID, "properties", "altitude"],
      new Map({
        length: length,
        _length: _length,
        _unit: _unit
      })
    );
    return { updatedState: state };
  }

  static createHole(state, layerID, type, lineID, offset, properties) {
    let holeID = IDBroker.acquireID();
    let hole = state.catalog.factoryElement(type, {
      id: holeID,
      url: state.catalog.getIn(['elements', type, 'info', 'url']),
      type,
      offset,
      line: lineID
    }, properties);

    state = state.setIn(['scene', 'layers', layerID, 'holes', holeID], hole);
    state = state.updateIn(['scene', 'layers', layerID, 'lines', lineID, 'holes'],
      holes => holes.push(holeID));
    // get vertex order/////
    let element = state.scene.layers.getIn([layerID, "lines", lineID]);
    let layer = state.getIn(["scene", "layers", layerID]);
    let vetName0 = element.vertices.get(0);
    let vetName1 = element.vertices.get(1);
    let verticesArray = [];
    layer.areas.forEach(data => {
      verticesArray.push(data.vertices.toJS());
    });
    for (let i = 0; i < verticesArray.length; i++) {
      let vertices = verticesArray[i];
      if (vertices.includes(vetName0) && vertices.includes(vetName1)) {
        let index0 = vertices.indexOf(vetName0);
        let index1 = vertices.indexOf(vetName1);
        // if vertex order chanege//
        if (index1 + 1 == index0) {
          return { updatedState: state, hole };
        }
        if (index0 == 0 && index1 == (vertices.length - 1)) {
          return { updatedState: state, hole };
        }
        // ////////////////////////
      }
    }
    // ///////////////////////////
    properties = hole.properties.toJS();
    if (properties.hasOwnProperty("flip_orizzontal")) {
      properties["flip_orizzontal"] = true;
    }
    if (properties.hasOwnProperty("flip_horizontal")) {
      properties["flip_horizontal"] = true;
    }
    state = Hole.setJsProperties(state, layerID, holeID, properties).updatedState;
    return { updatedState: state, hole };
  }
  
  static toggleLoadingCabinet(state) {
    let { scene } = state;
    console.log('scene.isLoadingCabinet: ', !scene.isLoadingCabinet);
    // scene = scene.set('isLoadingCabinet', !scene.isLoadingCabinet);
    // state = state.set('scene', scene);
    state = state.setIn(['scene', 'isLoadingCabinet'], !scene.isLoadingCabinet);
    return { updatedState: state };
  }
  static endLoading(state) {
    let { scene } = state;
    state = state.setIn(['scene', 'loadFlag'], true);
    return { updatedState: state };
  }
}

export { Item as default };
